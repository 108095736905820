import React from 'react'

const FooterBottom = () => {
  return (
    <div className="py-10 w-full">
      <p className="text-base text-center text-gray-500">
       
      </p>
    </div>
  );
}

export default FooterBottom